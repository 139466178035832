import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const LitImage = ({ src, alt, style, loading = "eager", image, data }) => {
  // Gatsby-Plugin-Image does not support svg. These can be rendered using <img/> without performance drops
  if (typeof window === "undefined") return null;

  if (image?.extension === "svg" || src?.includes(".svg")) {
    if (image === undefined) console.log("undefined image", src, image, data);
    return <img src={image.publicURL} alt={alt} style={style} />;
  } else {
    // Check to ensure that the image check succeeded
    if (image === null || image === undefined) return null;

    return (
      // Brief fix as some images don't use childImageSharp anymore, mainly from contentful
      <GatsbyImage
        image={
          image.childImageSharp
            ? image.childImageSharp.gatsbyImageData
            : image.gatsbyImageData
        }
        alt={alt}
        style={style}
        loading={loading}
      />
    );
  }
};

export function GetImage(src, data) {
  // Filter out the result we need
  const image = data.images?.edges
    ? data.images.edges.find((n) => {
        return n.node.relativePath.includes(src);
      })
    : data;

  // If No Image, return null
  if (!image) {
    if (
      process.env.NODE_ENV === "development" ||
      process.env.GATSBY_ENVIRONMENT === "development"
    )
      console.log(`No Image found for ${src}`, data);

    return null;
  }

  return image.node ? image.node : image;
}

export default LitImage;
