import React from "react";
import { Link } from "gatsby";
import LitImage from "../components/LitImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_container">
        <div className="footer_header">
          <h3>
            Lit Fibre <br />
            Internet done properly
          </h3>
        </div>
        <div className="footer_social">
          <div className="footer_social_logo">
            <Link to="/" className="footer_social_logo_link">
              <LitImage image="../images/foot_logo.png" alt="LIT Logo" />
            </Link>
          </div>
          <div className="footer_social_icons">
            <a href="mailto:peering@litfibre.com">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
