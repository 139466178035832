import React, { useState } from "react";
import { Link } from "gatsby";

const Nav = ({ siteTitle, hideNav }) => {
  const [navIsOpen, setNavIsOpen] = useState(false);

  const [screenSize, setScreenSize] = useState(0);

  const navClosed = () => {
    setNavIsOpen(!navIsOpen);
  };

  return (
    <header className="bootstrap">
      <nav className="navigation">
        <div className="navigation-content-container">
          <div className="nav-mobile-container">
            <div className="nav-logo-container">
              <Link
                to="/"
                onClick={() => {
                  setNavIsOpen(false);
                }}
              >
                <div className="nav-logo" />
              </Link>
            </div>
            {!hideNav && (
              <div
                className={`nav-burger-container ${
                  navIsOpen && "burger-animate"
                }`}
              >
                <button onClick={navClosed}>
                  <span className="nav-burger"></span>
                </button>
              </div>
            )}
          </div>
          {/* PurgeCSS include: nav-open, nav-closed, rotate, nav-submenu-open, nav-submenu-closed  */}
          {!hideNav && (
            <div
              className={
                screenSize < 800 && (navIsOpen ? "nav-open" : "nav-closed")
              }
            >
              <div className={`nav-content`}>
                <ul className="nav-container">
                  <li className="nav-top-level-links">
                    <Link to="/bgp-information" onClick={navClosed}>
                      BGP Information
                    </Link>
                  </li>
                  <li className="nav-top-level-links">
                    <Link to="/looking-glass" onClick={navClosed}>
                      Looking Glass
                    </Link>
                  </li>
                  <li className="nav-top-level-links">
                    <Link to="/peering" onClick={navClosed}>
                      Peering
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};
// Nav.propTypes = {
//   siteTitle: PropTypes.string,
// };

// Nav.defaultProps = {
//   siteTitle: '',
// };
export default Nav;
