import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
const Layout = ({ children }) => {
  /* PurgeCSS include: Yellow, White, Black */
  return (
    <div className="layout">
      <Nav />
      <main>{children}</main>
      <Footer />
    </div>
  );
};
export default Layout;
